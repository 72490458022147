<template>
  <div class="order-info-detail">
    <dl class="order-source" style="margin-bottom: 1%">
      <dt style="margin-top: 1%">
        <div class="infoTitle" id="BasicInformation">
          <i class="leftLine"></i>
          {{ $t('基本信息') }}
          <router-link
            target="_blank"
            :to="{
              name: 'orderEntry',
              query: { omsOrderSn: orderDetail.orderSn },
            }"
            class="btn-a ml6 mr6 fz14"
          >
            {{ $t(`复制订单`) }}
          </router-link>
          <!-- 编辑按钮 -->
          <template v-if="canEdit">
            <a
              v-if="priv('f_sale_order_address_modify') && !orderSourceEditable"
              href="javascript:;"
              @click.prevent="editOrderSource"
              class="fz14"
            >
              {{ $t('编辑') }}
            </a>
            <a
              href="javascript:;"
              v-if="priv('f_sale_order_address_modify') && orderSourceEditable"
              @click.prevent="confirmOrderSource"
              style="margin-left: 5px; font-size: 14px"
            >
              <i class="iconfont icon-save" style="color: #52c41a"></i>
            </a>
            <a
              href="javascript:;"
              v-if="priv('f_sale_order_address_modify') && orderSourceEditable"
              @click.prevent="cancelOrderSource"
              style="margin-left: 5px; font-size: 14px"
            >
              <i class="iconfont icon-cancel" style="margin-left: 5px"></i>
            </a>
          </template>
        </div>
      </dt>
      <dd>
        <div>
          <a-row>
            <a-col :span="7" class="col-item">
              <!-- 店铺名称 -->
              <label>{{ $t(`店铺`) }}</label>
              <div class="fz14">{{ orderDetail.shop.shopName }}</div>
            </a-col>
            <a-col :span="7" class="col-item">
              <!-- 平台 -->
              <label>{{ $t(`平台`) }}</label>
              <div class="fz14">
                {{ getPlatformName(orderDetail.shop.platform) }}
                <!-- {{ isStore ? '睿本云' : orderDetail.shop.platform }} -->
              </div>
              <div class="fz14" v-if="orderDetail.distributor?.platformValue">
                {{ orderDetail.distributor.platformValue }}
              </div>
            </a-col>
            <a-col :span="7" class="col-item">
              <!-- 用户ID -->
              <label>{{ $t(`用户ID`) }}</label>
              <div class="fz14" style="word-break: break-all">{{ orderDetail.buyer }}</div>
            </a-col>
            <a-col :span="7" class="col-item" v-if="isStore">
              <!-- 用户ID -->
              <label>{{ $t(`预计到货时间`) }}</label>
              <div class="fz14" style="word-break: break-all">
                <div class="flex-ct" v-if="editLatestDeliveryTime">
                  <DatePicker
                    id="latestDeliveryTime"
                    @choose="
                      (data) => {
                        chooseLatestDeliveryTime(data)
                      }
                    "
                    :init-date-time="orderDetail.latestDeliveryTime"
                    :needhms="false"
                  ></DatePicker>
                  <a-icon
                    type="check-circle"
                    class="text-link mr10 ml6"
                    @click="modifyDeliverTime"
                  />
                  <a-icon type="close-circle" class="text-link" @click="cancelModify" />
                </div>
                <template v-else>
                  <span>{{ getDate(orderDetail.latestDeliveryTime) }}</span>
                  <a-icon
                    class="text-link ml6"
                    type="edit"
                    @click="editTimeHandle"
                    v-if="canEdit"
                  />
                </template>
              </div>
            </a-col>
            <a-col :span="7" class="col-item">
              <!-- 收件人 -->
              <label>{{ $t(`收件人`) }}</label>
              <a-input
                allowClear
                type="text"
                :read-only="!orderSourceEditable"
                :disabled="basicShow"
                v-model.trim="orderDetail.receiverName"
              />
            </a-col>
            <a-col :span="7" class="col-item">
              <!-- 手机号 -->
              <label>{{ $t(`手机号`) }}</label>

              <a-input
                allowClear
                type="text"
                :read-only="!orderSourceEditable"
                :disabled="basicShow"
                v-model.trim="orderDetail.receiverMobile"
              />
            </a-col>
            <a-col :span="7" class="col-item">
              <!-- 电话 -->
              <label>{{ $t(`电话`) }}</label>
              <a-input
                allowClear
                type="text"
                :read-only="!orderSourceEditable"
                :disabled="basicShow"
                v-model.trim="orderDetail.receiverPhone"
              />
            </a-col>
            <a-col :span="7" class="col-item">
              <!-- 支付方式 -->
              <label>{{ $t(`支付方式`) }}</label>
              <template v-if="orderSourceEditable">
                <SelectPayment
                  style="width: 100%"
                  v-if="orderDetail.pay"
                  :default-payment="orderDetail.pay.payId"
                  disabled
                  @selected="selectedPayment"
                />
              </template>
              <template v-else>
                <a-input
                  allowClear
                  v-if="orderDetail.pay"
                  disabled
                  read-only
                  :class="{
                    red: orderDetail.pay.payId == '2' || orderDetail.pay.payId == '3',
                  }"
                  v-model="orderDetail.pay.payName"
                />
              </template>
            </a-col>
            <a-col :span="7" class="col-item">
              <!-- 订单类型 -->
              <label>{{ $t(`订单类型`) }}</label>
              <a-input disabled :value="orderDetail.orderTypeValue" />
            </a-col>
            <a-col :span="7" class="col-item" v-if="orderDetail.orderTags.length > 0">
              <!-- 订单类型 -->
              <label>{{ $t(`订单子类型`) }}</label>
              <a-input disabled :value="getOrderSubType()" />
            </a-col>
            <a-col :span="7" class="col-item">
              <!-- 发货方式 -->
              <label>{{ $t(`发货方式`) }}</label>
              <DeliveryTypeSelect disabled :value.sync="orderDetail.deliveryType" />
            </a-col>
            <a-col :span="7" class="col-item">
              <!-- 省-->
              <label>{{ $t(`省`) }}</label>
              <SelectRegion
                v-if="orderSourceEditable"
                region-type="1"
                :parent-id="parentId"
                @selected="selectedProvince"
                :disabled="!orderSourceEditable"
                :default-region-id="orderDetail.receiverProvince.regionId"
              />
              <a-input
                allowClear
                v-else
                disabled
                v-model="orderDetail.receiverProvince.regionName"
              />
            </a-col>
            <a-col :span="7" class="col-item">
              <!-- 市-->
              <label>{{ $t(`市`) }}</label>
              <SelectRegion
                v-if="orderSourceEditable"
                region-type="2"
                :parent-id="orderDetail.receiverProvince.regionId"
                @selected="selectedCity"
                :disabled="!orderSourceEditable"
                :class="{ 'not-editable': !orderSourceEditable }"
                :default-region-id="orderDetail.receiverCity.regionId"
              />

              <a-input allowClear v-else disabled v-model="orderDetail.receiverCity.regionName" />
            </a-col>
            <a-col :span="7" class="col-item">
              <!-- 区 -->
              <label>{{ $t(`区`) }}</label>
              <SelectRegion
                v-if="orderSourceEditable"
                region-type="3"
                :parent-id="orderDetail.receiverCity.regionId"
                @selected="selectedDistrict"
                :disabled="!orderSourceEditable"
                :class="{ 'not-editable': !orderSourceEditable }"
                :default-region-id="orderDetail.receiverDistrict.regionId"
              />
              <a-input
                v-else
                allowClear
                v-model="orderDetail.receiverDistrict.regionName"
                disabled
              ></a-input>
            </a-col>
            <a-col
              :span="7"
              class="col-item"
              v-if="orderDetail.receiverStreet?.regionId || orderSourceEditable"
            >
              <!-- 街道 -->
              <label>{{ $t(`街道`) }}</label>
              <SelectRegion
                v-if="orderSourceEditable"
                region-type="4"
                :parent-id="orderDetail.receiverDistrict.regionId"
                @selected="selectedStreet"
                :disabled="!orderSourceEditable"
                :class="{ 'not-editable': !orderSourceEditable }"
                :default-region-id="orderDetail.receiverStreet.regionId"
              />
              <a-input
                allowClear
                :disabled="basicShow"
                v-else
                v-model="orderDetail.receiverStreet.regionName"
              ></a-input>
            </a-col>
            <a-col :span="7" class="col-item">
              <!-- 具体地址-->
              <label>{{ $t(`顾客地址`) }}</label>
              <a-input
                allowClear
                :disabled="basicShow"
                :read-only="!orderSourceEditable"
                v-model.trim="orderDetail.receiverAddress"
              />
            </a-col>
            <a-col :span="7" class="col-item">
              <!-- 邮编-->
              <label>{{ $t(`邮编`) }}</label>
              <a-input
                allowClear
                :disabled="basicShow"
                type="text"
                v-model.trim="orderDetail.receiverZip"
                :read-only="!orderSourceEditable"
                pattern="^[0-9]+$"
              />
            </a-col>
            <a-col :span="7" v-if="orderDetail.distributorName" class="col-item">
              <!-- 分销商名称 -->
              <label>{{ $t(`分销商名称`) }}</label>
              <a-input disabled :value="orderDetail.distributorName" />
            </a-col>
          </a-row>
        </div>
      </dd>
    </dl>
    <a-divider style="height: 10px"></a-divider>
    <dl class="order-message">
      <dt>
        <div class="infoTitle" id="MessageInfo">
          <i class="leftLine"></i>
          {{ $t('订单备注') }}
          <a
            href="javascript:;"
            v-if="priv('f_sale_order_desc_add')"
            class="btn-a"
            @click.prevent="AddMessage"
            style="font-weight: normal; margin-right: 6px; margin-left: 5px; font-size: 14px"
          >
            {{ $t(`添加日志`) }}
          </a>
          <a
            href="javascript:;"
            v-if="!memoEditable"
            @click.prevent="editMemo"
            style="font-weight: normal; font-size: 14px"
          >
            {{ $t(`修改留言`) }}
          </a>
          <a
            href="javascript:;"
            v-if="memoEditable"
            @click.prevent="confirmMemo"
            style="margin-right: 5px; font-size: 14px"
          >
            <i class="iconfont icon-save" style="color: #52c41a"></i>
          </a>
          <a
            href="javascript:;"
            v-if="memoEditable"
            @click.prevent="cancelMemo"
            style="font-size: 14px"
          >
            <i class="iconfont icon-cancel" style="margin-left: 5px"></i>
          </a>
        </div>
      </dt>
      <dd>
        <div>
          <a-row>
            <a-col :span="7" style="margin-right: 4%">
              <label>{{ $t(`买家留言`) }}</label>
              <div>
                <span v-if="!memoEditable" class="red" style="line-height: 28px">
                  {{ orderDetail.buyerMemo }}
                </span>
                <a-input allowClear v-else type="text" v-model="orderDetail.buyerMemo" />
              </div>
            </a-col>
            <a-col :span="7">
              <label>{{ $t(`卖家留言`) }}</label>
              <div>
                <span v-if="!memoEditable" class="red" style="line-height: 28px">
                  {{ orderDetail.sellerMemo }}
                </span>
                <a-input allowClear v-else type="text" v-model="orderDetail.sellerMemo" />
              </div>
            </a-col>
          </a-row>
          <a-row class="mt10">
            <a-col :span="24">
              <div class="flex">
                <label class="mr8">{{ $t('图片备注') }}</label>
                <UploadImage
                  :needSizeCheck="false"
                  :disabled="!memoEditable"
                  :multiple="memoEditable ? 9 : 0"
                  :imageList.sync="uploadImageUrlList"
                />
              </div>
            </a-col>
          </a-row>
        </div>
      </dd>
    </dl>

    <a-divider style="height: 10px"></a-divider>

    <dl class="order-shipment" style="margin-bottom: 1%">
      <dt>
        <div class="infoTitle" id="DistributionInformation">
          <i class="leftLine"></i>
          {{ $t('配送信息') }}
          <template v-if="canEdit">
            <a
              style="margin-left: 5px; font-size: 14px"
              href="javascript:;"
              v-if="priv('f_sale_order_warehouse_update') && !orderShipmentEditable"
              @click.prevent="editOrderShipment"
            >
              {{ $t('编辑') }}
            </a>
            <a
              style="margin: 0 5px; font-size: 14px"
              href="javascript:;"
              v-if="priv('f_sale_order_warehouse_update') && orderShipmentEditable"
              @click.prevent="confirmOrderShipment"
            >
              <i class="iconfont icon-save" style="color: #52c41a"></i>
            </a>
            <a
              style="font-size: 14px"
              href="javascript:;"
              v-if="priv('f_sale_order_warehouse_update') && orderShipmentEditable"
              @click.prevent="cancelOrderShipment"
            >
              <i class="iconfont icon-cancel" style="margin-left: 5px"></i>
            </a>
          </template>
        </div>
      </dt>
      <dd>
        <div>
          <a-row>
            <a-col :span="7" style="margin-right: 4%">
              <label>{{ $t(`仓库`) }} {{ orderDetail.warehouse.warehouseTypeValue }}</label>
              <SearchWarehouse
                :disabled="!orderShipmentEditable"
                :code="orderDetail.warehouse.wmsWarehouseCode"
                :shopList="orderDetail.shopList"
                @selected="selectedWarehouse"
              />
            </a-col>
            <a-col
              :span="7"
              style="margin-right: 4%"
              v-if="orderDetail.deliveryType === 'EXPRESS' || orderDetail.shipping.shippingCode"
            >
              <!-- 快递 -->
              <label>
                {{ $t(`快递`) }}
              </label>
              <SelectShipment
                @selected="selectedShipment"
                :placeholder="$t(`请选择快递`)"
                :shopCode="orderDetail.shop.shopCode || orderDetail.shop.shopNick"
                :platform="orderDetail.shop.platform"
                :wmsAppCode="orderDetail.warehouse.warehouseType"
                :warehouseCode="orderDetail.warehouse.wmsWarehouseCode"
                :defaultShippingName="defaultShippingName"
                :disabled="!orderShipmentEditable || !orderDetail.warehouse.wmsWarehouseCode"
                :class="{ 'not-editable': !orderShipmentEditable }"
                style="width: 100%"
              />
            </a-col>
            <a-col :span="7">
              <!-- 揽收方式 -->
              <label>
                {{ $t(`揽收方式`) }}
              </label>
              <a-select
                v-model="orderDetail.collectMethod"
                :disabled="!orderShipmentEditable"
                style="width: 100%"
                :placeholder="$t(`揽收方式`)"
              >
                <a-select-option :value="item" v-for="item in collectMethodList" :key="item">
                  {{ $t(item) }}
                </a-select-option>
              </a-select>
            </a-col>
          </a-row>
          <a-row style="margin-top: 10px">
            <a-col :span="7" style="margin-right: 4%">
              <!-- 面单号 -->
              <label>
                {{ $t(`面单号`) }}
                <a v-if="orderDetail.labelFile" @click="pdfShow = true">{{ $t('预览') }}</a>
                <a
                  v-if="+orderDetail.isIntegration === 0 && orderShipmentEditable"
                  @click="showWaybillModal"
                >
                  {{ $t('点击上传/更换面单文件') }}
                </a>
              </label>
              <div>
                <a-input disabled v-model="orderDetail.waybillNo" />
              </div>
            </a-col>
            <a-col :span="7" style="margin-right: 4%">
              <!-- 预估计重 -->
              <label>{{ $t(`预估计重`) + '(KG)' }}</label>
              <div>
                <a-input allowClear disabled read-only v-model="orderDetail.estimatedWeight" />
              </div>
            </a-col>
            <a-col :span="7">
              <!-- 预估抛重 -->
              <label>{{ $t(`预估抛重`) + '(KG)' }}</label>
              <div>
                <a-input
                  allowClear
                  disabled
                  read-only
                  v-model="orderDetail.estimatedVolumeWeight"
                />
              </div>
            </a-col>
          </a-row>
          <a-row style="margin-top: 10px">
            <a-col :span="7">
              <!-- 预估尺寸 -->
              <label>
                {{
                  $t(`预估尺寸`) + '(' + $t(`长`) + '*' + $t(`宽`) + '*' + $t(`高`) + ':CM' + ')'
                }}
              </label>
              <div>
                <a-input
                  allowClear
                  disabled
                  read-only
                  :value="`${orderDetail.estimatedSizeLength}*${orderDetail.estimatedSizeWidth}*${orderDetail.estimatedSizeHeight}`"
                />
              </div>
            </a-col>
          </a-row>
        </div>
      </dd>
    </dl>
    <a-divider style="height: 10px"></a-divider>

    <dl class="order-shipment">
      <dt>
        <div class="infoTitle" id="ProductInfo">
          <i class="leftLine"></i>
          {{ $t('商品信息') }}
          <template>
            <!-- 拆弹转仓 -->
            <!-- <a
              style="font-weight: normal; margin: 0 6px; font-size: 14px"
              @click="jumpSplit"
              >{{ $t(`拆单转仓`) }}</a
            > -->
            <a
              v-if="canEdit"
              @click="jumpSplit"
              style="font-weight: normal; margin: 0 6px; font-size: 14px"
            >
              {{ $t(`拆单转仓`) }}
            </a>
          </template>
        </div>
      </dt>
      <dd>
        <a-row>
          <a-col :span="7" style="margin-right: 4%">
            <!-- 选择原始订单添加商品 -->
            <label>{{ $t(`选择原始订单添加商品`) }}</label>
            <a-select allowClear style="width: 100%" v-model="addGoods.platformOrderId">
              <a-select-option
                v-for="(item, index) in orderDetail.platformOrderIds"
                :key="index"
                name="platformId"
                :value="item"
              >
                {{ item }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="12" style="margin-right: 2%">
            <!-- 商品名称 -->
            <label>{{ $t(`商品名称`) }}</label>
            <SearchSku @selected="fetchSku" />
          </a-col>
          <a-col :span="3" style="margin-top: 22px" v-if="canEdit">
            <a-button
              type="primary"
              @click="showModal"
              :disabled="addGoods.addGoodsDisabled"
              icon="plus"
            >
              {{ $t(`添加`) }}
            </a-button>
          </a-col>
        </a-row>
        <a-modal v-model="visible" :title="$t(`商品信息`)" @ok="addGoodsToOrder">
          <p>
            <!-- 单品 赠品 -->
            <label class="radio-inline" style="padding-left: 24px">
              <input
                type="radio"
                name="isGift"
                value="0"
                :disabled="orderDetail.payStatus == 'EARNEST_PAID'"
                v-model="addGoods.isGift"
                @change="changeIsGift"
              />
              {{ $t(`商品`) }}
            </label>
            <label class="radio-inline" style="padding-left: 24px">
              <input
                type="radio"
                name="isGift"
                value="1"
                :disabled="orderDetail.payStatus == 'EARNEST_PAID'"
                v-model="addGoods.isGift"
                @change="changeIsGift"
              />
              {{ $t(`赠品`) }}
            </label>
          </p>
          <a-row type="flex" justify="space-between">
            <a-col :span="11">
              <label>{{ $t(`数量`) }}</label>
              <a-input type="number" min="1" v-model="addGoods.addQuantity" />
            </a-col>
            <a-col :span="11">
              <label>{{ $t(`单价`) }}</label>
              <a-input
                type="number"
                v-model="addGoods.addPrice"
                :disabled="addGoods.isGift == '1'"
              />
            </a-col>
          </a-row>
          <a-row style="margin-top: 1%" type="flex" justify="space-between">
            <a-col :span="11">
              <!-- 商品优惠 -->
              <label>{{ $t(`商品优惠`) }}</label>
              <a-input
                allowClear
                type="text"
                v-model="addGoods.goodDiscountFee"
                :disabled="addGoods.isGift == '1'"
              />
            </a-col>
            <a-col :span="11">
              <!-- 订单优惠分担 -->
              <label>{{ $t(`订单优惠分摊`) }}</label>
              <a-input
                allowClear
                type="text"
                v-model="addGoods.divideDiscountFee"
                :disabled="addGoods.isGift == '1'"
              />
            </a-col>
          </a-row>
          <a-row style="margin-top: 1%" type="flex" justify="space-between">
            <a-col>
              <!-- 税费 -->
              <label class="control-label">{{ $t(`税费`) }}</label>
              <a-input
                allowClear
                type="text"
                v-model="addGoods.addTaxFee"
                :disabled="addGoods.isGift == '1'"
              />
            </a-col>
          </a-row>
          <!-- 添加 -->
          <!-- <a-button type="primary" @click.prevent="addGoodsToOrder">
            {{ $t(`添加`) }}
          </a-button> -->
        </a-modal>
        <!-- <div class="form-inline add-good">
            <div class="form-group"> -->
        <!-- 数量 -->
        <!-- <label class="control-label">{{ $t(`数量`) }}</label>
              <a-input type="number" min="1" v-model="addGoods.addQuantity" />
            </div>
            <div class="form-group"> -->
        <!-- 单价 -->
        <!-- <label class="control-label">{{ $t(`单价`) }}</label>
              <a-input
                type="text"
                v-model="addGoods.addPrice"
                :disabled="addGoods.isGift == '1'"
              />
            </div> -->
        <!-- </div> -->
        <div class="order-goods-table">
          <a-table
            id="target"
            :rowKey="(record, index) => index"
            ref="TableInfo"
            :pagination="false"
            :columns="columns"
            :dataSource="tempList"
            :scroll="{ x: 1200 }"
            style="margin-top: 1%"
          >
            <!-- 原始订单号
            <template slot="OriginalOrderNumber" slot-scope="text, record">
              {{ record.orderSn }}
            </template>

            预估重量
            <template slot="EstimatedWeightOfOrder" slot-scope="text, record">
              {{ record.orderWeight }}
            </template> -->

            <!-- 图片 -->
            <template slot="Image" slot-scope="text, record">
              <img @click="previewImg(record.imageUrl)" width="80" :src="record.imageUrl" alt="" />
            </template>

            <!-- 商家编码-->
            <template slot="ProductInfo" slot-scope="text, record">
              <div>{{ $t('名称') }}:{{ record.sku.skuName }}</div>
              <div>
                {{ $t('编码') }}:{{ record.skuCode }}
                <a-tag color="orange" v-if="orderTypeFilter[record.skuType]">
                  {{ orderTypeFilter[record.skuType] }}
                </a-tag>
                <template v-if="record.gift">
                  <a-tag color="orange">
                    {{ $t('赠') }}
                  </a-tag>
                  <div
                    class="text-link"
                    v-if="record.giftStrategyId"
                    @click="goToGiftDetail(record)"
                  >
                    {{ record.giftStrategyName || $t('查看赠品规则') }}
                  </div>
                </template>
              </div>
              <div>
                {{ $t('条码') }}:{{ record.sku?.barcode }}
                <div class="fz12" v-if="record.batchNo">
                  {{ $t('批次编码') }}: {{ record.batchNo }}
                </div>
                <div class="fz12" v-if="record.produceCode">
                  {{ $t('生产批号') }}: {{ record.produceCode }}
                </div>
                <div class="fz12" v-if="record.productDate">
                  {{ $t('生产日期') }}: {{ record.productDate }}
                </div>
                <div class="fz12" v-if="record.expireDate">
                  {{ $t('失效日期') }}: {{ record.expireDate }}
                </div>
              </div>
            </template>

            <!-- 商品数量 -->
            <template slot="GoodsNum" slot-scope="text, record">
              {{ record.numShow }}
            </template>

            <!-- 商品分摊 -->
            <template slot="GoodsNumShow" slot-scope="text, record">
              {{ record.totalFee }}
            </template>

            <!-- 订货数量 -->
            <template slot="rawNum" slot-scope="text, record">
              <span>{{ toThousandAmount(record.rawNum) }}</span>
              <span>{{ record.orderRawUnit }}</span>
            </template>

            <!-- 单品件数 -->
            <template slot="GoodsItemNum" slot-scope="text, record">
              <a-input v-if="record.editable" type="text" v-model="record.number" />
              <span v-else>{{ toThousandAmount(record.number) }}</span>
              <span>{{ record.orderUnit }}</span>
            </template>

            <!-- 实际出库 -->
            <template slot="actualNumber" slot-scope="text, record">
              <span>{{ toThousandAmount(record.actualNumber) }}</span>
              <span>{{ record.orderUnit }}</span>
            </template>

            <!-- 可用库存数 -->
            <template slot="availableInventory" slot-scope="text, record">
              <a-spin :spinning="searchInventory">
                <span>{{ text }}</span>
                <span>{{ record.orderUnit }}</span>
              </a-spin>
              <div
                class="text-link"
                @click="getReservedOrder(record)"
                v-if="isStockout && text === 0"
              >
                {{ $t('查看预定订单') }}
              </div>
            </template>

            <!-- 单价-->
            <template slot="UnitPrice" slot-scope="text, record">
              <a-input v-if="record.editable" type="text" v-model="record.price" />
              <span v-else>{{ toThousandAmount(record.price) }}</span>
            </template>

            <!-- 商品优惠-->
            <template slot="DiscountFee" slot-scope="text, record">
              <a-input v-if="record.editable" type="text" v-model="record.discountFee" />
              <span v-else>{{ toThousandAmount(record.discountFee) }}</span>
            </template>

            <!-- 订单优惠分摊-->
            <template slot="DivideDiscountFee" slot-scope="text, record">
              <a-input v-if="record.editable" type="text" v-model="record.divideDiscountFee" />
              <span v-else>{{ toThousandAmount(record.divideDiscountFee) }}</span>
            </template>

            <!-- 货款小计-->
            <template slot="GoodsFee" slot-scope="text, record">
              {{ record.goodsFee }}
            </template>

            <!-- 订单优惠-->
            <template slot="OrderDiscountFee" slot-scope="text, record">
              {{ record.discountFee }}
            </template>

            <!-- 操作-->
            <template slot="Action" slot-scope="text, record">
              <div class="text-info mb6" @click="showChangeSkuModal(record)">
                {{ $t(`修改`) }}sku
              </div>
              <div
                class="text-info mb6"
                @click="showSkuBatchModal(record)"
                v-if="record.availableInventory"
              >
                {{ $t(`添加商品批次`) }}
              </div>
              <template>
                <template
                  v-if="
                    priv('f_sale_order_goods_update') &&
                    canEdit &&
                    orderDetail.shippingStatus == 'PENDING_WAYBILL'
                  "
                >
                  <a
                    href="javascript:;"
                    v-if="!record.editable"
                    @click.prevent="editOrderGood(record)"
                  >
                    <i class="icon iconfont icon-a-theEditor"></i>
                  </a>
                  <a
                    href="javascript:;"
                    v-if="record.editable"
                    @click.prevent="confirmOrderGood(record)"
                  >
                    <i class="iconfont icon-save" style="color: #52c41a"></i>
                  </a>
                  <a
                    href="javascript:;"
                    v-if="record.editable"
                    @click.prevent="cancelOrderGood(record)"
                  >
                    <i class="iconfont icon-cancel" style="margin-left: 5px"></i>
                  </a>
                </template>
                <template
                  v-if="
                    record.canDelete &&
                    (isStore ||
                      orderDetail.payStatus != 'EARNEST_PAID' ||
                      (orderDetail.payStatus == 'EARNEST_PAID' &&
                        Number(record.taxFee) == 0 &&
                        Number(record.goodsFee) == 0 &&
                        Number(record.totalFee) == 0 &&
                        Number(record.divideDiscountFee) == 0 &&
                        Number(record.discountFee) == 0 &&
                        Number(record.price) == 0))
                  "
                >
                  <a href="javascript:;" @click.prevent="deleteOrderGood(record)">
                    <i class="iconfont icon-delete" style="color: #f5222d; margin-left: 5px"></i>
                  </a>
                </template>
                <template v-else>
                  <a-popover>
                    <template slot="content">
                      {{
                        $t(
                          `该商品为普通商品，无法删除，只能进行替换操作；如果想要删除，需要修改商品类型为虚拟商品/赠品。`
                        )
                      }}
                    </template>
                    <a href="javascript:;" @click.prevent="toGoodsDetails(record)">
                      <i class="iconfont icon-delete" style="color: gray; margin-left: 5px"></i>
                    </a>
                  </a-popover>
                </template>
              </template>
            </template>
          </a-table>
          <div v-for="(item, i) of tempList.slice(0, 1)" :key="i">
            <a-row class="footTable" type="flex" justify="start">
              <a-col :span="18">{{ $t(`原始订单号`) }}：{{ item.orderSn }}</a-col>
            </a-row>
            <a-row class="footTable" type="flex" justify="start">
              <!-- <a-col :span="8">{{ $t(`商品数量`) }}：{{ item.orderGoodsNum }}</a-col> -->
              <a-col :span="8">{{ $t(`单品件数`) }}：{{ item.totalSkuNum }}</a-col>
              <a-col :span="8">{{ $t(`预估重量`) }}：{{ item.orderWeight }}</a-col>
            </a-row>
            <a-row class="footTable" type="flex" justify="start">
              <a-col :span="8">
                {{ $t(`总商品优惠`) }}：{{ toThousandAmount(allDiscountFee) }}
              </a-col>
              <a-col :span="8">
                {{ $t(`总订单优惠`) }}：{{ toThousandAmount(allDivideDiscountFee) }}
              </a-col>
              <a-col :span="8">{{ $t(`邮费优惠`) }}：{{ item.postDiscountFee }}</a-col>
            </a-row>
          </div>
          <table class="table">
            <tfoot>
              <td style="line-height: 20px; color: #111; padding-top: 10px" class="flex-ct">
                <!-- 总商品金额 -->
                {{ $t(`总商品金额`) }}：
                <a-input
                  type="text"
                  v-if="orderGoodsFeeEditable"
                  v-model="orderDetail.totalAmount"
                />
                <span v-else>{{ toThousandAmount(orderDetail.totalAmount) }}</span>
                <!-- 邮费 -->
                <span class="ml20">{{ $t(`邮费`) }}：</span>
                <a-input type="text" v-if="orderGoodsFeeEditable" v-model="orderDetail.postFee" />
                <span v-else>{{ toThousandAmount(orderDetail.postFee) }}</span>
                <!-- 贷款总额 -->
                <!-- {{ $t(`货款总额`) }}：
                <a-input
                  type="text"
                  class="not-editable"
                  read-only
                  v-model="orderDetail.goodsFee"
                /> -->

                <!-- 总金额优惠 -->
                <a-popover>
                  <template slot="content">
                    {{ $t(`总优惠金额=订单优惠+商品优惠总和`) }}
                  </template>
                  <span class="ml20">{{ $t(`总优惠金额`) }}：</span>
                </a-popover>
                <span>{{ toThousandAmount(totalDiscountFee) }}</span>
                <!-- 应收金额 -->
                <a-popover>
                  <template slot="content">
                    {{ $t(`应收金额=应收小计+邮费`) }}
                  </template>
                  <span class="ml20">{{ $t(`应收金额`) }}：</span>
                </a-popover>
                <span>{{ toThousandAmount(orderDetail.totalFee) }}</span>
                <div
                  v-if="
                    priv('f_sale_order_goods_update') &&
                    canEdit &&
                    orderDetail.shippingStatus == 'PENDING_WAYBILL' &&
                    orderDetail.payStatus != 'EARNEST_PAID'
                  "
                  style="text-align: right; float: right; margin-left: 20px"
                >
                  <a
                    href="javascript:;"
                    v-if="!orderGoodsFeeEditable"
                    @click.prevent="editOrderGoodsFee"
                  >
                    <a-icon type="edit" />
                  </a>
                  <a
                    href="javascript:;"
                    v-if="orderGoodsFeeEditable"
                    @click.prevent="confirmOrderGoodsFee"
                  >
                    <a-icon type="check-circle" style="color: #52c41a" />
                  </a>
                  <a
                    href="javascript:;"
                    class="ml6"
                    v-if="orderGoodsFeeEditable"
                    @click.prevent="cancelOrderGoodsFee"
                  >
                    <a-icon type="close-circle" />
                  </a>
                </div>
              </td>
            </tfoot>
          </table>
        </div>
      </dd>
    </dl>
    <dl v-if="priv('f_sale_invoice_apply_add')" class="order-invoice">
      <dd style="min-height: 30px">
        <div class="col-md-12 no-padding form-horizontal">
          <div class="form-group" v-for="(item, index) in invoiceInfo" :key="index">
            <div class="col-md-11 no-padding">
              <label class="col-md-1 control-label">{{ $t(`发票号`) }}：</label>
              <div class="col-md-1 control-label">
                <span>{{ item.invoiceNo }}</span>
              </div>
              <label class="col-md-1 control-label">{{ $t(`申请人`) }}：</label>
              <div class="col-md-1 control-label">
                <span>{{ item.creator ? item.creator.name : '' }}</span>
              </div>
              <label class="col-md-1 control-label">{{ $t(`发票类型`) }}：</label>
              <div class="col-md-1 control-label">
                <span>{{ typeObj[item.type] }}</span>
              </div>
              <label class="col-md-1 control-label">{{ $t(`抬头`) }}：</label>
              <div class="col-md-1 control-label">
                <span>{{ item.title }}</span>
              </div>
              <label class="col-md-1 control-label">{{ $t(`发票状态`) }}：</label>
              <div class="col-md-1 control-label">
                <span>{{ typeObj[item.status] }}</span>
              </div>
              <label v-if="item.billingMode == 'HARD'" class="col-md-1 control-label">
                {{ $t(`快递`) }}：
              </label>
              <div v-if="item.billingMode == 'HARD'" class="col-md-1 control-label">
                <span v-if="item.sendShipping != undefined">
                  {{ item.sendShipping.shippingName }}
                </span>
              </div>
              <label v-if="item.billingMode == 'HARD'" class="col-md-2 control-label">
                {{ $t(`快递单号`) }}:
              </label>
              <div v-if="item.billingMode == 'HARD'" class="col-md-2 control-label">
                <span>{{ item.sendLogisticsNumber }}</span>
              </div>
            </div>
          </div>
        </div>
      </dd>
    </dl>
    <AddNoteModal
      v-if="showMessageAddModal"
      :show-overlay="showOverlay"
      @cancel="cancelMessageAdd"
      @confirm="confirmCommentAction"
    />

    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <div class="align-center">
        <img alt="example" style="width: 90%" :src="previewImage" />
      </div>
    </a-modal>

    <!-- 修改sku -->
    <a-modal v-model="changeSkuVisible" :title="$t('修改商品')" @ok="changeSku">
      <SearchSku @selected="selectGoodsChangeSku"></SearchSku>
    </a-modal>

    <!-- 修改sku批次 -->
    <a-modal
      width="70%"
      v-model="changeSkuBatchVisible"
      :title="$t('添加商品批次') + '(' + $t('指定商品出库的批次编号') + ')'"
      @ok="updateBatchCode"
    >
      <a-table
        :rowKey="(record, index) => index"
        :loading="batchTableoading"
        :columns="skuBatchColumns"
        :dataSource="skuBatchList"
        :pagination="false"
        :row-selection="{ type: 'radio', selectedRowKeys, onChange: onSelectChange }"
      >
        <template slot="OrderSn" slot-scope="text, record">
          <span class="text-link" @click="goToOrderDetail(record)">{{ text }}</span>
        </template>
      </a-table>
    </a-modal>

    <a-modal
      v-model="pdfShow"
      class="pdf"
      title="pdf"
      :footer="null"
      width="70%"
      :dialog-style="{ top: '20px' }"
    >
      <PdfPreview :pdfUrl="orderDetail.labelFile"></PdfPreview>
    </a-modal>

    <a-modal
      v-model="waybillModalVisible"
      :title="$t('上传/更换面单文件')"
      @ok="hideWaybillModal"
      :dialog-style="{ top: '20px' }"
    >
      <div class="pdf-upload-box">
        <a-form-model>
          <a-form-model-item :label="$t('面单号')" required>
            <a-input v-model="waybillModalData.waybillNo" />
          </a-form-model-item>
          <a-form-model-item :label="$t('面单文件')" required>
            <template v-if="waybillModalData.labelFile">
              <span class="text-info" @click="waybillModalData.labelFile = undefined">
                {{ $t('删除') }}
              </span>
              <PdfPreview :pdfUrl="waybillModalData.labelFile" />
            </template>
            <a-upload
              v-else
              accept="application/pdf"
              :before-upload="beforeUpload"
              :custom-request="changeUpload"
            >
              <a-button>
                <a-icon type="upload" />
                {{ $t('点击上传') }}
              </a-button>
            </a-upload>
            <div class="tips">{{ $t('面单文件必填，仅支持pdf') }}</div>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>

    <a-modal
      :maskClosable="false"
      v-model="reservedOrderVisible"
      :title="$t('商品预定订单列表')"
      :footer="null"
      :dialog-style="{ top: '20px' }"
    >
      <div class="fz20 mb10">{{ $t('商品条码') }}: {{ reservedOrderBarcode }}</div>
      <a-table
        :rowKey="(record, index) => index"
        :columns="reservedOrderColumns"
        :dataSource="reservedOrderList"
      >
        <template slot="OrderSn" slot-scope="text, record">
          <span class="text-link" @click="goToOrderDetail(record)">{{ text }}</span>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { Modal } from 'ant-design-vue'
import http from '@service/axios'
import api from '@service/api'
import { getCookie } from '@/common/cookie'
import SearchWarehouse from '@component/searchWarehouse'
import SelectShipment from '@component/selectShipment'
import SelectRegion from '@component/selectRegion'
import SearchSku from '@component/searchSku'
import SelectPayment from '@component/selectPayment'
import DeliveryTypeSelect from '@component/deliveryTypeSelect'
import AddNoteModal from '@component/addNoteModal'
import PdfPreview from '@component/pdfPreview'
import UploadImage from '@component/uploadImage'
import DatePicker from '@component/datePicker'

export default {
  props: {
    orderId: {
      default: '',
    },
    propsOrderDetail: {
      default: {
        originOrderSns: [],
        originOrders: [],
      },
    },
    is_group: {
      default: false,
    },
  },
  components: {
    AddNoteModal,
    SelectShipment,
    SelectRegion,
    SearchSku,
    SelectPayment,
    PdfPreview,
    UploadImage,
    SearchWarehouse,
    DeliveryTypeSelect,
    DatePicker,
  },
  watch: {
    propsOrderDetail: {
      immediate: true,
      handler(val) {
        val.shopList = [
          {
            shopCode: val.shop?.shopCode,
            platform: val.shop?.platform,
          },
        ]
        this.orderDetail = val || {}
        if (val.imageNote) {
          let list = val.imageNote.split(',')
          this.uploadImageUrlList = list.map((img_item, img_index) => ({
            uid: '-' + (img_index + 1),
            name: `image${img_index}.png`,
            status: 'done',
            url: img_item,
          }))
        }
      },
    },
    dataSource() {
      // 同步表与footer滚动
      let dom = this.$refs.TableInfo.$el.querySelectorAll('.ant-table-body')[0]
      dom.addEventListener(
        'scroll',
        () => {
          this.$refs.TableInfo.$el.querySelectorAll('.ant-table-body')[1].scrollLeft =
            dom.scrollLeft
        },
        true
      )
    },
  },
  provide() {
    return {
      page: 'orderDetails',
    }
  },
  data() {
    return {
      visible: false,
      tempList: [],
      basicShow: true,
      showMessage: true,
      showOverlay: true,
      showInvoiceModal: false,
      showMessageAddModal: false,
      orderSourceEditable: false,
      orderShipmentEditable: false,
      orderGoodsFeeEditable: false,
      orderInvoiceEditable: false,
      memoEditable: false,
      // orderSource: {},
      orderShipment: {},
      orderGoodsFee: {},
      commentActions: [],
      invoiceInfo: [],
      memo: {
        orderId: '',
        buyerMemo: '',
        sellerMemo: '',
      },
      orderSource: {
        orderId: '',
        distributorId: '',
        distributorName: '',
        buyer: '',
        receiverName: '',
        receiverMobile: '',
        receiverPhone: '',
        receiverProvince: '',
        receiverProvinceName: '',
        receiverCity: '',
        receiverCityName: '',
        receiverDistrict: '',
        receiverDistrictName: '',
        receiverStreet: '',
        receiverStreetName: '',
        receiverAddress: '',
      },
      orderShippingFacility: {
        orderId: '',
      },
      orderFee: {
        orderId: '',
        postFee: 0,
        discountFee: 0,
      },
      orderGood: {
        orderGoodsId: '',
        goodsId: '',
        styleValue1: '',
        styleValue2: '',
        styleValue3: '',
        number: 0,
        price: 0,
        discountFee: 0,
        divideDiscountFee: 0,
      },
      addGoodToOrder: {
        orderId: '',
        gift: 0,
        partyId: '',
        skuId: '',
        number: 0,
        price: 0,
        taxFee: 0,
      },
      goodsType: {
        currentGoodsType: 1,
      },
      addGoods: {
        platformOrderId: '',
        addGoodsDisabled: true,
        styleType1Display: false,
        styleType2Display: false,
        styleType3Display: false,
        addStyleType1: {
          active: '',
          styleType: {
            name: '',
            id: '',
          },
          styleValues: [],
        },
        addStyleType2: {
          active: '',
          styleType: {
            name: '',
            id: '',
          },
          styleValues: [],
        },
        addStyleType3: {
          active: '',
          styleType: {
            name: '',
            id: '',
          },
          styleValues: [],
        },
        addOuterSkuId: '',
        addOuterIid: '',
        addGoodsId: '',
        addGoodsName: '',
        addPrice: '',
        addQuantity: '',
        isGift: 1,
        addTaxFee: '',
        goodDiscountFee: '',
        divideDiscountFee: '',
      },
      colors: ['#797979', 'red', '#FEA812', '#6FDD36', '#1D6DCF', '#762FD9'],
      previewVisible: false,
      previewImage: '',
      changeSkuVisible: false,
      changeSkuBatchVisible: false,
      changeOrderGoodsId: '',
      changeGoodsSku: {},
      parentId: '',
      allDiscountFee: 0,
      allDivideDiscountFee: 0,
      totalDiscountFee: 0,
      pdfShow: false,
      orderDetail: {},
      uploadImageUrlList: [],
      waybillModalVisible: false,
      waybillModalData: {
        waybillNo: undefined,
        labelFile: undefined,
      },

      reservedOrderBarcode: undefined,
      reservedOrderVisible: false,
      reservedOrderList: [],

      searchInventory: true,
      skuBatchList: [],
      selectedRowKeys: [],
      batchTableoading: false,

      latestDeliveryTimeNew: '',
      editLatestDeliveryTime: false,
      fileInfo: {},
    }
  },
  created() {
    this.handleTableDetail()
    let barcodeList = this.tempList.map((v) => v.barcode)
    this.queryInventory(barcodeList)
    this.countryMapping(this._props.propsOrderDetail)
  },
  mounted() {
    if (!this.canEdit) {
      this.columns.pop()
    }
    this.$nextTick(() => {
      var breakExcep = {}
      try {
        let length = this.orderDetail.originOrderSns.length
        for (let i = 0; i < length; i++) {
          if (this.orderDetail.originOrderSns[i] != '') {
            this.addGoods.platformOrderId = this.orderDetail.originOrderSns[i]
            throw breakExcep
          }
        }
      } catch (e) {
        if (e == breakExcep) {
          return false
        }
      }
      if (this.orderDetail.orderStatus == 'CHECK_PASS') {
        this.columns.pop()
      }
    })
  },
  computed: {
    ...mapState(['orderSubTypeMapping']),
    pdfUploadApi() {
      //这个接口支持所有类型文件上传
      let cpid = this.$store.getters.partyId
      return api.preUploadFile + '?cpid=' + cpid
    },
    token() {
      return getCookie('d_session_id')
    },
    country() {
      return this.$store.getters.country.toUpperCase()
    },
    countryMap() {
      return this.$store.state.countryMap
    },
    columns() {
      return [
        {
          title: this.$t(`图片`),
          scopedSlots: { customRender: 'Image' },
          key: 'Image',
          width: 100,
        },
        {
          title: this.$t(`商品信息`),
          scopedSlots: { customRender: 'ProductInfo' },
          key: 'ProductInfo',
          width: 220,
        },
        {
          title: this.$t(`规格`),
          dataIndex: 'attributeValue',
          width: 120,
        },
        {
          title: this.$t('平台子订单号'),
          key: 'platformOrderGoodsId',
          dataIndex: 'originOrderGoods.platformOrderGoodsId',
          width: 250,
        },
        // {
        //   title: this.$t(`商品数量`),
        //   scopedSlots: { customRender: 'GoodsNum' },
        //   key: 'GoodsNum',
        //   width: 100,
        // },
        {
          title: this.$t(`商品分摊`),
          scopedSlots: { customRender: 'GoodsNumShow' },
          key: 'GoodsNumShow',
          width: 100,
        },
        {
          title: this.$t(`订货数量`),
          scopedSlots: { customRender: 'rawNum' },
          key: 'rawNum',
          width: 100,
        },
        {
          title: this.$t(`单品件数`),
          scopedSlots: { customRender: 'GoodsItemNum' },
          key: 'GoodsItemNum',
          width: 100,
        },
        {
          title: this.$t(`实际出库`),
          scopedSlots: { customRender: 'actualNumber' },
          key: 'actualNumber',
          width: 100,
        },
        {
          title: this.$t(`可用库存`),
          dataIndex: 'availableInventory',
          scopedSlots: { customRender: 'availableInventory' },
          width: 100,
        },
        // {
        //   title: this.$t(`单位`),
        //   dataIndex: 'orderUnit',
        //   width: 80,
        // },
        {
          title: this.$t(`单价`),
          scopedSlots: { customRender: 'UnitPrice' },
          key: 'UnitPrice',
          width: 120,
        },
        {
          title: this.$t(`商品优惠`),
          scopedSlots: { customRender: 'DiscountFee' },
          key: 'DiscountFee',
          width: 120,
        },
        {
          title: this.$t(`订单优惠分摊`),
          scopedSlots: { customRender: 'DivideDiscountFee' },
          key: 'DivideDiscountFee',
          width: 120,
        },
        // {
        //   title: this.$t(`货款小计`),
        //   scopedSlots: { customRender: "GoodsFee" },
        //   key: "GoodsFee",
        //   width: 100,
        // },
        {
          title: this.$t(`应收小计`),
          dataIndex: 'totalFee',
          width: 120,
          customRender: (text) => this.toThousandAmount(text),
        },
        // {
        //   title: this.$t(`订单优惠`),
        //   scopedSlots: { customRender: "OrderDiscountFee" },
        //   key: "OrderDiscountFee",
        //   width: 100,
        // },
        {
          title: this.$t(`操作`),
          scopedSlots: { customRender: 'Action' },
          width: 120,
          key: 'Action',
          fixed: 'right',
        },
      ]
    },
    preSaleBtnShow() {
      if (
        this.orderDetail.payStatus == 'EARNEST_PAID' &&
        this.orderDetail.totalSkuDiscountFee == 0 &&
        this.orderDetail.taxFee == 0 &&
        this.orderDetail.totalAmount == 0 &&
        this.orderDetail.totalFee == 0
      ) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.addGoods.isGift = 1
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.addGoods.addPrice = 0.0
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.addGoods.addTaxFee = 0.0
      }
      return (
        this.orderDetail.payStatus != 'EARNEST_PAID' ||
        (this.priv('f_sale_order_presale_modify') && this.orderDetail.payStatus == 'EARNEST_PAID')
      )
    },
    sendConditionTypeMapping() {
      return {
        PAID: this.$t(`先款后货`),
        COD_SHIP: 'COD',
        SELL_ON_CREDIT: this.$t(`赊销`),
      }
    },
    typeObj() {
      return {
        INIT: this.$t(`待开票`),
        INVOICED: this.$t(`已开票`),
        CANCELLED: this.$t(`已作废`),
        ADDED: this.$t(`增票`),
        COMMON: this.$t(`普票`),
      }
    },
    orderTypeFilter() {
      return this.constants.ORDER_TYPE_MAPPING
    },
    collectMethodList() {
      return this.constants.COLLECT_METHOD_LIST
    },
    canEdit() {
      return (
        this.orderDetail.orderStatus === 'WAIT_CHECK' ||
        this.orderDetail.orderStatus === 'TO_REVIEW'
      )
    },
    warehouseNick() {
      let warehouse = this.orderDetail.warehouse
      return (
        (warehouse.warehouseChannel || warehouse.warehouseType) + '_' + warehouse.wmsWarehouseCode
      )
    },
    reservedOrderColumns() {
      return [
        {
          title: this.$t(`订单号`),
          dataIndex: 'orderSn',
          scopedSlots: { customRender: 'OrderSn' },
        },
        {
          title: this.$t(`数量`),
          dataIndex: 'number',
        },
      ]
    },
    skuBatchColumns() {
      return [
        {
          title: this.$t(`商品条码`),
          dataIndex: 'barcode',
        },
        {
          title: this.$t(`数量`),
          dataIndex: 'quantity',
        },
        {
          title: this.$t(`库存状态`),
          dataIndex: 'inventoryType',
          customRender: (text) => {
            let obj = {
              ZC: this.$t('正常'),
              CC: this.$t('残次'),
              DJ: this.$t('冻结'),
            }
            return obj[text]
          },
        },
        {
          title: this.$t(`批次编码`),
          dataIndex: 'batchCode',
        },
        {
          title: this.$t(`生产批号`),
          dataIndex: 'produceCode',
        },
        {
          title: this.$t(`生产日期`),
          dataIndex: 'productDate',
        },
        {
          title: this.$t(`失效日期`),
          dataIndex: 'expireDate',
        },
      ]
    },
    isStockout() {
      let index = this.orderDetail.orderProcess.exceptionTagList.findIndex((item) => {
        return item.key === '缺货' || item.key === 'Out of stock'
      })
      return index > -1
    },
    defaultShippingName() {
      let str = ''
      if (this.orderDetail.shipping.shippingName) {
        str =
          this.orderDetail.shipping.shippingName +
          '-' +
          this.orderDetail.shipping.shippingAccountName
      }
      return str
    },
    isStore() {
      //是门店类型
      return this.$store.getters.isStore
    },
  },
  methods: {
    ...mapActions(['getShopInfoList']),
    countryMapping(orderDetail) {
      this.getShopInfoList((list) => {
        list.forEach((item) => {
          if (item.name === orderDetail.shop.shopName) {
            const country = item.country
            Object.keys(this.countryMap).forEach((key) => {
              if (key === country) {
                this.parentId = this.countryMap[key]
              }
            })
          }
        })
      })
    },
    jumpSplit() {
      this.$router.push({
        name: 'orderSplit',
        params: { actionType: 'DIFF_WAREHOUSE', orderId: this.orderDetail.orderId },
      })
    },
    showModal() {
      this.visible = true
    },
    mergeCells(text, data, key, index) {
      // 上一行该列数据是否一样
      if (index !== 0 && text === data[index - 1][key]) {
        return 0
      }
      let rowSpan = 1
      // 判断下一行是否相等
      for (let i = index + 1; i < data.length; i++) {
        if (text !== data[i][key]) {
          break
        }
        rowSpan++
      }
      return rowSpan
    },
    handleFooterShow(data) {
      for (let item of data) {
        return `
             ${this.$t(`原始订单号`)}：${item.orderSn}       ${this.$t(`预估重量`)}：${
          item.orderWeight
        }
        ${this.$t(`商品数量`)}：${item.orderGoodsNum}
        ${this.$t(`单品件数`)}：${item.totalSkuNum}
        ${this.$t(`订单优惠`)}：${item.totalSkuDiscountFee} `
      }
    },
    confirmCommentAction(data) {
      http({
        url: api.addOrderAction,
        type: 'post',
        data: {
          data: {
            color: data.color,
            orderId: this.orderId,
            type: 'COMMENT',
            subType: 'ADD',
            content: data.note,
            displayContent: data.note,
          },
        },
        hasLoading: false,
        success: (res) => {
          this.commentActions.push(res.data)
          this.showMessageAddModal = false
          this.$emit('reflesh-actions', true)
        },
        fail: () => {
          this.$error(this.$t(`添加备注失败，请重试~`))
        },
      })
    },
    toNumber(val) {
      return val ? 0 : Number(val)
    },
    AddMessage() {
      this.showMessageAddModal = true
    },
    cancelMessageAdd() {
      this.showMessageAddModal = false
    },
    editMemo() {
      this.memo.buyerMemo = this.orderDetail.buyerMemo
      this.memo.sellerMemo = this.orderDetail.sellerMemo
      this.memoEditable = true
    },
    confirmMemo() {
      let imageNote = this.uploadImageUrlList.map((item) => item.url).join(',')
      this.memo = {
        orderId: this.orderId,
        buyerMemo: this.orderDetail.buyerMemo,
        sellerMemo: this.orderDetail.sellerMemo,
        imageNote,
      }
      http({
        url: api.orderMemoModify,
        type: 'post',
        data: {
          data: this.memo,
        },
        hasLoading: true,
        success: () => {
          this.memoEditable = false
          this.$emit('reflesh-actions', true)
          this.$emit('reflesh-order', true)
        },
      })
    },
    cancelMemo() {
      this.orderDetail.buyerMemo = this.memo.buyerMemo
      this.orderDetail.sellerMemo = this.memo.sellerMemo
      this.memoEditable = false
    },
    selectedWarehouse(data) {
      this.orderDetail.warehouse = data

      this.orderDetail.shipping.shippingCode = undefined
      this.orderDetail.shipping.shippingAccountCode = undefined
      this.orderDetail.shipping.shippingName = undefined
    },
    selectedShipment(data) {
      this.orderDetail.shipping.shippingCode = data.shippingCode
      this.orderDetail.shipping.shippingAccountCode = data.shippingAccountCode
    },
    editOrderShipment() {
      this.showMessage = false
      this.orderShippingFacility = {
        orderId: this.orderId,
        wmsWarehouseCode: this.orderDetail.warehouse.wmsWarehouseCode,
        shippingCode: this.orderDetail.shipping.shippingCode,
        shippingAccountCode: this.orderDetail.shipping.shippingAccountCode,
      }
      this.orderShipmentEditable = true
    },
    confirmOrderShipment() {
      this.showMessage = true
      //订单的仓库快递修改
      //验证是否为空
      if (!this.orderDetail.warehouse.wmsWarehouseCode) {
        this.$warning(this.$t(`请选择仓库`))
        return false
      }
      if (
        (this.orderDetail.deliveryType === 'EXPRESS' || this.orderDetail.shipping.shippingCode) &&
        !this.orderDetail.shipping.shippingAccountCode
      ) {
        this.$warning(this.$t(`请选择快递`))
        return false
      }
      let labelFile = undefined,
        waybillNo = undefined
      if (this.waybillModalData.labelFile) {
        labelFile = this.waybillModalData.labelFile
        waybillNo = this.waybillModalData.waybillNo
      }
      this.orderShippingFacility = {
        orderId: this.orderId,
        warehouseCode: this.orderDetail.warehouse.wmsWarehouseCode,
        warehouseChannel:
          this.orderDetail.warehouse.warehouseChannel || this.orderDetail.warehouse.warehouseType,
        shippingCode: this.orderDetail.shipping.shippingCode,
        shippingAccountCode: this.orderDetail.shipping.shippingAccountCode,
        labelFile,
        waybillNo,
        collectMethod: this.orderDetail.collectMethod,
      }
      http({
        url: api.modifyOrderFacilityShipping,
        type: 'post',
        data: {
          data: this.orderShippingFacility,
        },
        hasLoading: true,
        success: () => {
          this.orderShipmentEditable = false
          this.$emit('reflesh-actions', true)
          this.$emit('reflesh-order', true)
        },
        fail: (err) => {
          if (err.subCode == 'modify-warehouse-shipping-invalid-error') {
            Modal.confirm({
              title: this.$t(`提示`),
              content: () => <div style="color:red;">{err.subMsg}</div>,
              okText: this.$t(`是`),
              cancelText: this.$t(`否`),
              onOk: () => {
                this.orderShippingFacility.isForce = true
                http({
                  url: api.modifyOrderFacilityShipping,
                  type: 'post',
                  data: {
                    data: this.orderShippingFacility,
                  },
                  hasLoading: true,
                  success: () => {
                    this.orderShipmentEditable = false
                    this.$emit('reflesh-actions', true)
                    this.$emit('reflesh-order', true)
                  },
                })
              },
              class: 'test',
            })
          }
        },
      })
    },
    cancelOrderShipment() {
      this.showMessage = true
      this.orderDetail.warehouse.wmsWarehouseCode = this.orderShippingFacility.wmsWarehouseCode
      this.orderDetail.shipping.shippingCode = this.orderShippingFacility.shippingCode
      this.orderDetail.shipping.shippingAccountCode = this.orderShippingFacility.shippingAccountCode
      this.orderDetail.waybillNo = undefined
      this.orderShipmentEditable = false
    },
    /**=========配送信息模块 end============**/

    /**=========来源去向模块 start============**/
    selectedProvince(data) {
      // ;
      this.orderDetail.receiverProvince.regionId = data.region_id
      this.orderDetail.receiverProvince.regionName = data.display_name
      this.orderDetail.receiverCity.regionId = ''
      this.orderDetail.receiverCity.regionName = ''
      this.orderDetail.receiverDistrict.regionId = ''
      this.orderDetail.receiverDistrict.regionName = ''
    },
    selectedCity(data) {
      this.orderDetail.receiverCity.regionId = data.region_id
      this.orderDetail.receiverCity.regionName = data.display_name
      this.orderDetail.receiverDistrict.regionId = ''
      this.orderDetail.receiverDistrict.regionName = ''
      this.orderDetail.receiverStreet.regionId = ''
      this.orderDetail.receiverStreet.regionName = ''
    },
    selectedDistrict(data) {
      this.orderDetail.receiverDistrict.regionId = data.region_id
      this.orderDetail.receiverDistrict.regionName = data.display_name
      this.orderDetail.receiverStreet.regionId = ''
      this.orderDetail.receiverStreet.regionName = ''
    },
    selectedStreet(data) {
      this.orderDetail.receiverStreet.regionId = data.region_id
      this.orderDetail.receiverStreet.regionName = data.display_name
    },
    handleTableDetail() {
      if (!this.orderDetail.orderGoodses?.length) return
      this.tempList = []
      let allDivideDiscountFee = 0,
        allDiscountFee = 0
      if (this.orderDetail.platformOrderIds?.length === 1) {
        this.addGoods.platformOrderId = this.orderDetail.platformOrderIds[0]
      }
      for (let item of this.orderDetail.orderGoodses) {
        let ids = this.orderDetail.platformOrderIds?.toString()
        item.forEach((e) => {
          e.skuCode = e.sku.skuCode
          e.barcode = e.sku.barcode
          e.orderSn = ids
          e.orderWeight = this.orderDetail.orderWeight
          e.totalSkuDiscountFee = this.orderDetail.totalSkuDiscountFee
          e.orderGoodsNum = this.orderDetail.orderGoodsNum
          e.totalSkuNum = this.orderDetail.totalSkuNum
          e.goodsFee = this.orderDetail.goodsFee
          e.postDiscountFee = this.orderDetail.postDiscountFee
          e.feeNum = (Number(this.orderDetail.totalFee) - Number(this.orderDetail.postFee)).toFixed(
            2
          )
          e.fenxiaoTotalFee = Number(this.orderDetail.fenxiaoTotalFee).toFixed(6)
          allDivideDiscountFee += e.divideDiscountFee
          allDiscountFee += e.discountFee
        })
        this.tempList.push(...item)
      }
      this.allDivideDiscountFee = allDivideDiscountFee.toFixed(6)
      this.allDiscountFee = allDiscountFee.toFixed(6)
      this.totalDiscountFee = (
        +this.orderDetail.postDiscountFee +
        allDivideDiscountFee +
        allDiscountFee
      ).toFixed(6)
    },
    editOrderSource() {
      this.basicShow = false
      this.parentId = this.parentId || this.orderDetail?.receiverProvince?.parent?.regionId
      this.orderSource = {
        orderId: this.orderId,
        distributorId: this.orderDetail.distributorId,
        distributorName: this.orderDetail.distributorName,
        buyer: this.orderDetail.buyer,
        receiverName: this.orderDetail.receiverName,
        receiverMobile: this.orderDetail.receiverMobile,
        receiverPhone: this.orderDetail.receiverPhone,
        receiverProvince: this.orderDetail.receiverProvince.regionId,
        receiverProvinceName: this.orderDetail.receiverProvince.regionName,
        receiverCity: this.orderDetail.receiverCity.regionId,
        receiverCityName: this.orderDetail.receiverCity.regionName,
        receiverDistrict: this.orderDetail.receiverDistrict.regionId || '',
        receiverDistrictName: this.orderDetail.receiverDistrict.regionName || '',
        receiverStreet: this.orderDetail.receiverStreet.regionId || '',
        receiverStreetName: this.orderDetail.receiverStreet.regionName || '',
        receiverAddress: this.orderDetail.receiverAddress,
      }
      this.orderSourceEditable = true
    },
    confirmOrderSource() {
      //验证是否为空的问题
      this.basicShow = true
      // if (this.orderDetail.distributorName == '' && this.orderDetail.buyer == '') {
      //   this.$warning(this.$t(`用户ID和分销商不能同时为空！`))
      //   return false
      // }
      if (
        this.orderDetail.receiverName == '' ||
        this.orderDetail.receiverProvince.regionId == '' ||
        this.orderDetail.receiverCity.regionId == '' ||
        this.orderDetail.receiverAddress == '' ||
        this.orderDetail.pay.payId == ''
      ) {
        this.$warning(this.$t(`收件人、支付方式、省-市-区、详细地址为必填字段，不能为空！`))
        return false
      }
      if (!this.orderDetail.receiverMobile && !this.orderDetail.receiverPhone) {
        this.$warning(this.$t(`手机号和电话不能同时为空！`))
        return false
      }
      // if (
      //   this.orderDetail.receiverMobile &&
      //   !this.orderDetail.receiverMobile.match(/^1[3-9]\d{9}$/)
      // ) {
      //   layer.msg(this.$t(`手机号码不符合规定格式！`), { timeout: 2000 });
      //   return false;
      // }
      if (this.orderDetail.receiverPhone && !this.orderDetail.receiverPhone.match(/^[0-9-]+$/)) {
        this.$warning(this.$t(`电话号码不符合规定格式！`))
        return false
      }
      this.orderSource = {
        orderId: this.orderId,
        distributorId: this.orderDetail.distributorId,
        distributorName: this.orderDetail.distributorName,
        buyer: this.orderDetail.buyer,
        receiverName: this.orderDetail.receiverName,
        receiverMobile: this.orderDetail.receiverMobile,
        receiverPhone: this.orderDetail.receiverPhone,
        receiverProvince: this.orderDetail.receiverProvince.regionId,
        receiverProvinceName: this.orderDetail.receiverProvince.regionName,
        receiverCity: this.orderDetail.receiverCity.regionId,
        receiverCityName: this.orderDetail.receiverCity.regionName,
        receiverDistrict: this.orderDetail.receiverDistrict.regionId,
        receiverDistrictName: this.orderDetail.receiverDistrict.regionName,
        receiverStreet: this.orderDetail.receiverStreet.regionId,
        receiverStreetName: this.orderDetail.receiverStreet.regionName,
        receiverAddress: this.orderDetail.receiverAddress,
        payId: this.orderDetail.pay.payId,
        payInfo: `{\"payId\":"${this.orderDetail.pay.payId}"}`,
        receiverZip: this.orderDetail.receiverZip,
      }
      http({
        url: api.modifyOrderReceiverInfo,
        type: 'post',
        data: {
          data: this.orderSource,
        },
        hasLoading: true,
        success: () => {
          this.orderSourceEditable = false
          this.$emit('reflesh-actions', true)
          this.$emit('reflesh-order', true)
        },
      })
    },
    cancelOrderSource() {
      this.basicShow = true
      this.orderDetail.distributorId = this.orderSource.distributorId
      this.orderDetail.distributorName = this.orderSource.distributorName
      this.orderDetail.buyer = this.orderSource.buyer
      this.orderDetail.receiverName = this.orderSource.receiverName
      this.orderDetail.receiverMobile = this.orderSource.receiverMobile
      this.orderDetail.receiverPhone = this.orderSource.receiverPhone
      this.orderDetail.receiverProvince.regionId = this.orderSource.receiverProvince
      this.orderDetail.receiverProvince.regionName = this.orderSource.receiverProvinceName
      this.orderDetail.receiverCity.regionId = this.orderSource.receiverCity
      this.orderDetail.receiverCity.regionName = this.orderSource.receiverCityName
      this.orderDetail.receiverDistrict.regionId = this.orderSource.receiverDistrict
      this.orderDetail.receiverDistrict.regionName = this.orderSource.receiverDistrictName
      this.orderDetail.receiverStreet.regionId = this.orderSource.receiverStreet
      this.orderDetail.receiverStreet.regionName = this.orderSource.receiverStreetName
      this.orderDetail.receiverAddress = this.orderSource.receiverAddress
      this.orderSourceEditable = false
    },
    selectedPayment(data) {
      if (this.$isEmptyObject(data)) {
        this.orderDetail.pay.payId = ''
      } else {
        this.orderDetail.pay.payId = data.data
      }
    },
    /**=========来源去向模块 end============**/

    /**=========发票信息模块 start============**/
    editOrderInvoice() {
      this.orderInvoiceEditable = true
    },
    confirmOrderInvoice() {
      this.orderInvoiceEditable = false
      this.$emit('reflesh-actions', true)
    },
    cancelOrderInvoice() {
      this.orderInvoiceEditable = false
    },
    /**=========发票信息模块 end============**/

    /**================订单商品修改模块start==================**/
    editOrderGoodsFee() {
      this.orderFee = {
        orderId: this.orderDetail.orderId,
        postFee: this.orderDetail.postFee,
        discountFee: this.orderDetail.discountFee,
      }
      this.orderGoodsFeeEditable = true
    },
    confirmOrderGoodsFee() {
      //修改订单邮费和订单优惠
      //验证订单优惠和邮费
      if (this.orderDetail.postFee < 0 || this.orderDetail.discountFee < 0) {
        this.$warning(this.$t(`订单优惠和订单邮费必须大于等于0！`))
        return false
      }
      this.orderFee = {
        orderId: this.orderDetail.orderId,
        postFee: this.orderDetail.postFee,
        discountFee: this.orderDetail.discountFee,
      }
      http({
        url: api.modifyPostAndDiscountFee,
        type: 'post',
        data: {
          data: this.orderFee,
        },
        hasLoading: true,
        success: () => {
          this.orderGoodsFeeEditable = false
          this.$emit('reflesh-order', true)
          this.$emit('reflesh-actions', true)
        },
      })
    },
    cancelOrderGoodsFee() {
      this.orderDetail.postFee = this.orderFee.postFee
      this.orderDetail.discountFee = this.orderFee.discountFee
      this.orderGoodsFeeEditable = false
    },
    editOrderGood(record) {
      this.orderGood = {
        orderGoodsId: record.orderGoodsId,
        goodsId: record.sku.goods?.id,
        skuCode: record.sku.skuCode,
        number: record.number,
        price: record.price,
        discountFee: record.discountFee,
        divideDiscountFee: record.divideDiscountFee,
        styleValue1: record.sku.styleValue1 == undefined ? '' : record.sku.styleValue1.id,
        styleValue2: record.sku.styleValue2 == undefined ? '' : record.sku.styleValue2.id,
        styleValue3: record.sku.styleValue3 == undefined ? '' : record.sku.styleValue3.id,
      }
      record.editable = true
      var tempOrderGoodses = this.orderDetail.orderGoodses
      this.orderDetail.orderGoodses = []
      this.orderDetail.orderGoodses = tempOrderGoodses
    },

    confirmOrderGood(record) {
      if (!record.gift) {
        //非赠品
        if (
          record.number <= 0 ||
          record.price < 0 ||
          record.discountFee < 0 ||
          record.divideDiscountFee < 0
        ) {
          this.$warning(this.$t(`商品数量必须大于0，商品价格、商品优惠和订单优惠分摊必须大于等于0`))
          return false
        }
      } else {
        //赠品
        if (
          record.number <= 0 ||
          record.price != 0 ||
          record.discountFee != 0 ||
          record.divideDiscountFee != 0
        ) {
          this.$warning(this.$t(`商品数量必须大于0，赠品价格、商品优惠和订单优惠分摊必须等于0`))
          return false
        }
      }
      this.orderGood = {
        orderGoodsId: record.orderGoodsId,
        goodsId: record.sku.goods?.id,
        skuCode: record.sku.skuCode,
        number: record.number,
        price: Number(record.price).toFixed(6),
        discountFee: Number(record.discountFee).toFixed(6),
        divideDiscountFee: Number(record.divideDiscountFee).toFixed(6),
        styleValue1: record.sku.styleValue1 == undefined ? '' : record.sku.styleValue1.id,
        styleValue2: record.sku.styleValue2 == undefined ? '' : record.sku.styleValue2.id,
        styleValue3: record.sku.styleValue3 == undefined ? '' : record.sku.styleValue3.id,
      }

      http({
        url: api.modifyOrderGoods,
        type: 'post',
        data: {
          data: this.orderGood,
        },
        hasLoading: true,
        success: () => {
          this.$emit('reflesh-order', true)
          this.$emit('reflesh-actions', true)
          this.$success(this.$t(`操作成功`))
        },
      })
      document.getElementById('target').scrollIntoView()
    },
    cancelOrderGood(record) {
      record.sku.goods && (record.sku.goods.id = this.orderGood.goodsId)
      record.number = this.orderGood.number
      record.price = this.orderGood.price
      record.discountFee = this.orderGood.discountFee
      record.divideDiscountFee = this.orderGood.divideDiscountFee
      record.editable = false

      if (record.sku.styleValue1) {
        record.sku.styleValue1.id = this.orderGood.styleValue1
      }
      if (record.sku.styleValue2) {
        record.sku.styleValue2.id = this.orderGood.styleValue2
      }
      if (record.sku.styleValue3) {
        record.sku.styleValue3.id = this.orderGood.styleValue3
      }

      var tempOrderGoodses = this.orderDetail.orderGoodses
      this.orderDetail.orderGoodses = []
      this.orderDetail.orderGoodses = tempOrderGoodses
    },
    toGoodsDetails(record) {
      console.log(record)
    },
    deleteOrderGood(record) {
      if (
        this.orderDetail.orderGoodses.length == 1 &&
        this.orderDetail.orderGoodses[0].length == 1
      ) {
        this.$warning(this.$t(`该订单只有一个商品，不能删除！`))
        return false
      }
      if (confirm(this.$t(`确定删除该商品么？`))) {
        http({
          url: api.deleteOrderGoods,
          type: 'post',
          async: false,
          data: {
            data: {
              orderGoodsId: record.orderGoodsId,
            },
          },
          hasLoading: true,
          success: () => {
            this.$success(this.$t(`删除成功`))
            this.$emit('reflesh-order', true)
            this.$emit('reflesh-actions', true)
          },
        })
      }
      document.getElementById('target').scrollIntoView()
    },
    /**添加商品模块 start**/
    changeSingleOrGroup(data) {
      this.goodsType.currentGoodsType = data
      this.addGoods.addGoodsDisabled = true
      this.addGoods.styleType1Display = false
      this.addGoods.styleType2Display = false
      this.addGoods.styleType3Display = false
      this.addGoods.addStyleType1 = {}
      this.addGoods.addStyleType2 = {}
      this.addGoods.addStyleType3 = {}
      this.addGoods.addOuterSkuId = ''
      this.addGoods.addOuterIid = ''
      this.addGoods.addGoodsId = ''
      this.addGoods.addGoodsName = ''
      this.addGoods.skuId = ''
      this.addGoods.skuCode = ''
      this.addGoods.addPrice = ''
      this.addGoods.addQuantity = ''
      if (this.addGoods.isGift) {
        this.addGoods.addTaxFee = 0
        this.addGoods.addPrice = 0
      } else {
        this.addGoods.addTaxFee = ''
        this.addGoods.addPrice = ''
      }
    },
    selectGoodsSkuCode(data) {
      if (!this.$isEmptyObject(data)) {
        for (var key in data) {
          this.addGoods[key] = data[key]
        }
      }
    },
    selectGroupGoodsSkuCode(data) {
      if (!this.$isEmptyObject(data)) {
        for (var key in data) {
          this.addGoods[key] = data[key]
        }
      }
    },
    fetchGoodSkus(data) {
      if (!this.$isEmptyObject(data)) {
        for (var key in data) {
          this.addGoods[key] = data[key]
        }
      }
    },
    fetchSku(data) {
      if (!this.$isEmptyObject(data)) {
        this.addGoods.addGoodsId = data.goodsId
        this.addGoods.skuId = data.skuId
        this.addGoods.skuCode = data.skuCode
        this.addGoods.addOuterSkuId = data.skuCode
        this.addGoods.addQuantity = 1
        this.addGoods.addTaxFee = 0.0
        this.addGoods.goodDiscountFee = 0.0
        this.addGoods.divideDiscountFee = 0.0
        if (this.addGoods.isGift) {
          this.addGoods.addPrice = 0
          this.addGoods.addTaxFee = 0
        } else {
          this.addGoods.addPrice = data.price || 0
        }
        this.addGoods.addGoodsName = data.skuName
        this.addGoods.addGoodsDisabled = false
      }
    },
    showChangeSkuModal(record) {
      this.changeSkuVisible = true
      this.changeOrderGoodsId = record.orderGoodsId
    },
    showSkuBatchModal(record) {
      this.querySkuStockForBatch(record)
      this.changeSkuBatchVisible = true
      this.changeOrderGoodsId = record.orderGoodsId
    },
    async querySkuStockForBatch(record) {
      let barcode = record.sku.barcode
      this.batchTableoading = true
      let res = await http({
        url: api.querySkuStockForBatch,
        data: {
          warehouse_code: this.orderDetail.warehouse.warehouseCode,
          platform: this.orderDetail.platform,
          shop_code: this.orderDetail.shop.shopCode,
          barcode_list: [barcode],
        },
      })
      this.batchTableoading = false
      this.skuBatchList = res.result?.data?.[barcode] || []
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    async updateBatchCode() {
      if (this.selectedRowKeys.length === 0) return this.$message.warning(this.$t('请选择指定批次'))
      let batchData = this.skuBatchList[this.selectedRowKeys[0]]
      let data = {
        orderGoodsId: this.changeOrderGoodsId,
        batchNo: batchData.batchCode,
        produceCode: batchData.produceCode,
        productDate: batchData.productDate,
        expireDate: batchData.expireDate,
      }
      let res = await http({
        url: api.updateBatchCode,
        data,
      })
      if (res?.success) {
        this.$message.success(this.$t('修改成功'))
        this.changeSkuBatchVisible = false
        this.$emit('reflesh-actions', true)
        this.$emit('reflesh-order', true)
      }
    },
    selectGoodsChangeSku(data) {
      this.changeGoodsSku = data
    },
    changeSku() {
      http({
        url: api.updateGoodsSkuId,
        type: 'post',
        data: {
          data: {
            orderGoodsId: this.changeOrderGoodsId,
            skuId: this.changeGoodsSku.skuId,
            skuCode: this.changeGoodsSku.skuCode,
          },
        },
        hasLoading: true,
        success: () => {
          this.$success(this.$t(`操作成功`))
          this.changeSkuVisible = false
          this.$emit('reflesh-order', true)
          this.$emit('reflesh-actions', true)
        },
      })
    },
    fetchGroupGoodSkus(data) {
      this.addGoods.goodDiscountFee = 0.0
      this.addGoods.divideDiscountFee = 0.0
      if (!this.$isEmptyObject(data)) {
        for (var key in data) {
          this.addGoods[key] = data[key]
        }
        if (this.addGoods.isGift) {
          this.addGoods.addPrice = 0
          this.addGoods.addTaxFee = 0
        }
      }
    },
    addGoodsToOrder() {
      //添加商品
      //验证字段
      if (this.addGoods.addQuantity <= 0) {
        this.$warning(this.$t(`商品数量必须大于0`))
        return false
      }
      if (
        this.addGoods.addPrice < 0 ||
        this.addGoods.addTaxFee < 0 ||
        this.addGoods.goodDiscountFee < 0 ||
        this.addGoods.divideDiscountFee < 0
      ) {
        this.$warning(this.$t(`商品价格和税费必须大于等于0`))
        return false
      }
      if (
        this.addGoods.platformOrderId == '' &&
        (this.orderDetail.originOrderSns.length > 1 ||
          (this.orderDetail.originOrderSns.length == 1 && this.orderDetail.originOrderSns[0] != ''))
      ) {
        this.$warning(this.$t(`请选择原始订单添加商品`))
        return false
      }

      if (+this.goodsType.currentGoodsType === 1) {
        http({
          url: api.addOrderGoods,
          type: 'post',
          data: {
            data: {
              orderId: this.orderDetail.orderId,
              gift: this.addGoods.isGift,
              skuId: this.addGoods.skuId,
              skuCode: this.addGoods.skuCode,
              number: this.addGoods.addQuantity,
              price: this.addGoods.addPrice,
              taxFee: this.addGoods.addTaxFee,
              platformOrderId: this.addGoods.platformOrderId,
              discountFee: this.addGoods.goodDiscountFee,
              divideDiscountFee: this.addGoods.divideDiscountFee,
            },
          },
          hasLoading: true,
          success: () => {
            this.$emit('reflesh-order', true)
            this.$emit('reflesh-actions', true)
          },
        })
      } else {
        http({
          url: api.addOrderGroupGoods,
          type: 'post',
          data: {
            data: {
              orderId: this.orderDetail.orderId,
              gift: this.addGoods.isGift,
              groupId: this.addGoods.addGoodsId,
              groupNum: this.addGoods.addQuantity,
              price: this.addGoods.addPrice,
              taxFee: this.addGoods.addTaxFee,
              platformOrderId: this.addGoods.platformOrderId,
              discountFee: this.addGoods.goodDiscountFee,
              divideDiscountFee: this.addGoods.divideDiscountFee,
            },
          },
          hasLoading: true,
          success: () => {
            this.$emit('reflesh-order', true)
            this.$emit('reflesh-actions', true)
          },
        })
      }
    },
    changeIsGift() {
      if (this.addGoods.isGift == '1') {
        this.addGoods.addPrice = 0.0
        this.addGoods.addTaxFee = 0.0
        this.addGoods.goodDiscountFee = 0.0
        this.addGoods.divideDiscountFee = 0.0
      }
    },
    /**添加商品模块 end**/
    /**================订单商品修改模块end==================**/
    previewImg(url) {
      this.previewVisible = true
      this.previewImage = url
    }, // 查询barcode可用库存
    queryInventory(barcodeList) {
      this.searchInventory = true
      http({
        url: api.skuStockQuerySkuStockForOms,
        type: 'post',
        async: true,
        data: {
          warehouseCode: this.warehouseNick,
          barcodeList: barcodeList,
          platform: this.orderDetail.shop.platform,
        },
        success: (res) => {
          if (res.success) {
            let newData = [...this.tempList]
            newData.forEach((v) => {
              v.availableInventory = res.result[v.barcode].availableQuantity
            })
            this.tempList = [...newData]
            this.searchInventory = false
          }
        },
      })
    },
    showWaybillModal() {
      this.waybillModalData.waybillNo = this.orderDetail.waybillNo
      this.waybillModalData.labelFile = this.orderDetail.labelFile
      this.waybillModalVisible = true
    },
    hideWaybillModal() {
      if (!this.waybillModalData.waybillNo) {
        this.$message.warning(this.$t('请输入面单号'))
        return
      }
      if (!this.waybillModalData.labelFile) {
        this.$message.warning(this.$t('请上传面单文件'))
        return
      }
      this.orderDetail.waybillNo = this.waybillModalData.waybillNo
      this.waybillModalVisible = false
    },
    goToGiftDetail(record) {
      window.open(`/strategy_web/giftStrategyDetail/${record.giftStrategyId}/edit`)
    },
    async getReservedOrder(record) {
      let res = await http({
        url: api.queryReservedOrderSkus,
        data: {
          barcode: record.barcode,
        },
      })
      this.reservedOrderBarcode = record.barcode
      this.reservedOrderList = res.result
      this.reservedOrderVisible = true
    },
    goToOrderDetail(record) {
      window.open(location.origin + '/orderManage/omsOrderDetail/orderDetails/' + record.orderId)
    },
    getOrderSubType() {
      let item = this.orderDetail.orderTags.find((el) => el.type === 'orderSubType')
      return this.orderSubTypeMapping[item?.key]
    },

    getDate(date) {
      return date?.split(' ')?.[0]
    },
    editTimeHandle() {
      this.latestDeliveryTimeNew = this.orderDetail.latestDeliveryTime
      this.editLatestDeliveryTime = true
    },
    chooseLatestDeliveryTime(data) {
      this.latestDeliveryTimeNew = data.data
    },
    async modifyDeliverTime() {
      await http({
        url: api.modifyDeliverTime,
        type: 'post',
        hasLoading: true,
        data: { orderId: this.orderDetail.orderId, deliverTime: this.latestDeliveryTimeNew },
      })
      this.orderDetail.latestDeliveryTime = this.latestDeliveryTimeNew
      this.editLatestDeliveryTime = false
      this.$emit('reflesh-order', true)
      this.$emit('reflesh-actions', true)
    },
    cancelModify() {
      this.editLatestDeliveryTime = false
    },
    getPlatformName(platform) {
      switch (platform) {
        case 'RUI_BEN_YUN':
          return '瑞本云'
        case 'SHOPSPADE_B2B':
          return '采购平台'
      }
      return platform
    },
    changeUpload() {
      http({
        url: api.preUploadFile,
        data: {
          fileName: this.fileInfo.name,
          appCode: 'OMS',
          'Content-Type': 'application/pdf',
        },
        success: async (res) => {
          await fetch(res.result.upload_url, {
            method: 'PUT',
            body: this.fileInfo,
            headers: {
              'Content-Type': res.result.content_type,
            },
          })
          this.waybillModalData.labelFile = String(res.result.image_url)
        },
      })
    },
    beforeUpload(value) {
      this.fileInfo = value
      return true
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .ant-table-footer .ant-table-body {
  overflow: hidden !important;
}

dl {
  width: 100%;
  padding-bottom: 4px;
}

.footTable {
  background-color: #fafafa;
  line-height: 30px;
  font-size: 14px;
  padding: 0 1%;
}

.redText {
  color: red;
}

.order-info-detail {
  float: left;
  width: 100%;
  font-size: 12px;
  color: #444;
  background-color: $white;
}

.order-info-detail dl {
  margin-bottom: 0px;
  float: left;
}

.order-info-detail dt {
  font-size: 14px;
  color: #555;
  margin-top: 4px;
  margin-bottom: 2px;
  float: left;
}

.order-info-detail dd {
  background-color: $white;
  width: 100%;
  float: left;
}

.order-info-detail dd .form-group div {
  text-align: left;
  padding-right: 4px;
  padding-left: 4px;
}

a {
  font-weight: normal;
}

label {
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 4px;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.order-source dd .order-address div {
  padding-left: 0px;
  padding-right: 0px;
}

.order-source .order-address label {
  text-align: center;
}

.order-fee {
  border-bottom: solid 1px #d2d6de;
}

.order-fee input {
  padding-left: 1px;
  padding-right: 1px;
}

.order-payment table th {
  height: 30px;
  border-bottom: 2px solid #ccc;
  text-align: center;
}

.order-payment table td {
  text-align: center;
  padding-top: 2px;
  padding-bottom: 2px;
}

.pay-list {
  width: 30%;
  float: left;
  /*background-color: #eee;	*/
}

.pay-record {
  width: 65%;
  float: right;
}

.pay-list table {
  width: 95%;
  margin: 0 auto;
}

.pay-record table {
  width: 100%;
}

.form-group {
  margin-bottom: 2px;
}

.action-buttons {
  margin-top: 14px;
}

.action-button {
  text-align: center;
}

.btn-cancel {
  border-color: #367fa9;
  color: #367fa9;
}

.edit-icon-size {
  font-size: 1.9rem;
}

.fa-check {
  color: #78bf2a;
}

.fa-close {
  color: #f86767;
}

input.not-editable {
  border: 0 solid #d2d6de;
  background: $white;
}

select.not-editable {
  background-color: $white;
}

.goods-padding {
  padding-left: 30px;
}

.good-edit {
  text-align: center;
  width: 90%;
  margin-left: 5%;
}

.col-md-4 {
  padding-left: 0px;
  padding-right: 0px;
}

i {
  font-size: 16px;
}

.confirm-icon {
  color: #78bf2a;
}

.cancel-icon,
.delete-icon {
  color: #f86767;
}

.form-group {
  margin-left: 0px;
  margin-right: 0px;
}

.form-group .form-control {
  margin: 0 2px;
}

.table tfoot input {
  width: 100px;
}

.order-goods-table th {
  padding-right: 1px;
  padding-left: 1px;
}

.order-goods-table td {
  padding-left: 1px;
  padding-right: 1px;
  vertical-align: middle;
}

.table td {
  position: relative;
  text-align: center;
}

.table th {
  text-align: center;
}

.order-goods dd {
  padding-bottom: 10px;
}
.table > tbody {
  border-top: 2px solid #3697de !important;
  border-bottom: 2px solid #3697de !important;
}

.question {
  font-size: 12px;
  color: #999;
}

.padLeft {
  padding-left: 16px;
}

.add-good label {
  padding-top: 0px;
  margin-left: 10px;
}

.add-good input {
  width: 80px;
}

.icon-zeng {
  position: absolute;
  left: -1px;
  top: 0;
  overflow: hidden;
  line-height: 30px;
  font-size: 30px;
  float: left;
  cursor: pointer;
}

.gift-red {
  color: red;
}

.gift-blue {
  color: #3697de;
}

.qizhi {
  padding-left: 20px !important;
}

.icon-qizhi {
  font-size: 14px;
  color: #999;
  cursor: pointer;
}

.split-warn {
  color: #999;
  padding-left: 20px;
}

.text-info {
  cursor: pointer;
}
.pdf-upload-box {
  max-height: 70vh;
  overflow-y: auto;
}

.ant-divider-horizontal {
  margin: 10px 0;
}

.col-item {
  margin-right: 4%;
  margin-bottom: 1%;
  min-height: 43px;
}
</style>
